import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Span, Box, List, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { AiFillStar } from "react-icons/ai";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Willkommen bei RoadQuest Car Rentals
			</title>
			<meta name={"description"} content={"Ihre Reise beginnt hier!"} />
			<meta property={"og:title"} content={"Home | Willkommen bei RoadQuest Car Rentals"} />
			<meta property={"og:description"} content={"Ihre Reise beginnt hier!"} />
			<meta property={"og:image"} content={"https://fluxarix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fluxarix.com/img/45367671.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fluxarix.com/img/45367671.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fluxarix.com/img/45367671.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fluxarix.com/img/45367671.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fluxarix.com/img/45367671.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fluxarix.com/img/45367671.png"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenu" />
		</Components.Header>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="#fafaf9"
			display="flex"
			quarkly-title="Advantages/Features-28"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text
					as="h1"
					margin="0px"
					font="normal 900 56px/1.2 --fontFamily-sans"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					RoadQuest Car Rentals
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--base"
					font-size="20px"
					font-weight="300"
					color="--darkL2"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
				>
					Vielen Dank, dass Sie sich für RoadQuest Car Rentals entschieden haben, wo Ihr Komfort auf der Straße unser Hauptanliegen ist. Egal, ob Sie in den Urlaub fahren, sich auf eine Geschäftsreise vorbereiten oder einfach nur durch die Stadt cruisen, wir haben das ideale Fahrzeug für Sie. Unser unkomplizierter Mietprozess und unser hervorragender Kundenservice sorgen dafür, dass Ihre Erfahrung mit uns reibungslos und problemlos verläuft.
				</Text>
				<List
					margin="20px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="ai"
							icon={AiFillStar}
							size="20px"
							color="--grey"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--greyD2" font="18px/27px --fontFamily-sans">
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Erschwinglichkeit: {" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Wir bieten wettbewerbsfähige Preise, ohne dabei Abstriche bei Qualität oder Service zu machen.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="ai"
							icon={AiFillStar}
							size="20px"
							color="--grey"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--darkL2" font="18px/27px --fontFamily-sans">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								24/7-Kundensupport: {" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Unser sachkundiges und zugängliches Team steht Ihnen rund um die Uhr zur Verfügung.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="ai"
							icon={AiFillStar}
							size="20px"
							color="--grey"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" color="--darkL2">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Flexible Bedingungen: {" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
							>
								Egal, ob Sie ein Auto für eine Stunde oder einen Monat benötigen, wir bieten anpassbare Mietverträge, die zu Ihrem Zeitplan passen.
							</Span>
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image src="https://fluxarix.com/img/1.jpg" display="block" border-radius="32px" />
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://fluxarix.com/img/2.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Über uns
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Bei RoadQuest haben wir uns verpflichtet, eine vielfältige Flotte gut gewarteter Fahrzeuge anzubieten, die allen Vorlieben und Bedürfnissen gerecht werden. Von effizienten Kleinwagen für Stadtausflüge bis hin zu luxuriösen SUVs für einen Hauch von Eleganz und Komfort werden unsere Fahrzeuge ausgewählt, um erstklassige Sicherheit und Leistung zu bieten. Unser Engagement für Qualität und Kundenzufriedenheit zeichnet uns in der wettbewerbsintensiven Autovermietungslandschaft aus.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Fahren Sie mit uns
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Begeben Sie sich mit RoadQuest Car Rentals auf Ihr Abenteuer – Ihr Straßenabenteuer erwartet Sie dort!
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://fluxarix.com/img/3.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});